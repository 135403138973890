/* @import url("https://fonts.googleapis.com/css?family=Lato:400,700"); */
@font-face {
  font-family: "NHaasGrotesk-65Md";
  src: url("./fonts/83d6b8f7-bd47-4e8d-a359-27b74d3100f6.eot?#iefix");
  src: url("./fonts/83d6b8f7-bd47-4e8d-a359-27b74d3100f6.eot?#iefix")
      format("eot"),
    url("./fonts/75e1af8f-1a4c-475a-8b53-f27e52822b6b.woff2") format("woff2"),
    url("./fonts/2ba6fbd5-9c17-4733-af15-f49fbecc5c15.woff") format("woff"),
    url("./fonts/7dcf6c37-4fb4-4211-9808-6a39bfa89e0d.ttf") format("truetype");
}
@font-face {
  font-family: "NHaasGrotesk-75Bd";
  src: url("./fonts/ed82538c-6090-4c05-ac72-c636496df8de.eot?#iefix");
  src: url("./fonts/ed82538c-6090-4c05-ac72-c636496df8de.eot?#iefix")
      format("eot"),
    url("./fonts/c24b7456-b9fe-40ab-94af-ba8d3025fada.woff2") format("woff2"),
    url("./fonts/da47ecd2-feea-403e-b247-9f8f5bb5157b.woff") format("woff"),
    url("./fonts/0deba34f-9242-462b-a359-74e95714f821.ttf") format("truetype");
}
@font-face {
  font-family: "NeueHaasGroteskText";
  src: url("./fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix");
  src: url("./fonts/d24ae558-ac0f-4a43-96da-dd49b68947f5.eot?#iefix")
      format("eot"),
    url("./fonts/a14594bf-73de-4b5f-9792-9566994a021d.woff2") format("woff2"),
    url("./fonts/bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff") format("woff"),
    url("./fonts/53812a68-b352-4951-b19c-fe964db7ffe2.ttf") format("truetype");
}
